import React, { useState } from 'react';
import Layout from "../components/layout";
import AccentFrame from "../components/accentFrame"
import { makeStyles } from "@material-ui/core/styles";
import { graphql } from 'gatsby'
import { Box,
         FormControlLabel,
         Slider,
         Switch,
         Typography } from "@material-ui/core";
import { SectionTitle } from "../components/sectionTitle";
import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import { Section } from "../components/section";
import Li from "../components/li";
import PieChart from "../components/pieChart"
import theme from '../gatsby-theme-material-ui-top-layout/theme';
import Definition from "../components/definition";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import data from "../../content/trisomy.json";
import Legend from "../components/legend";
import trisomy1 from "../images/JA9BEW.jpg";
import trisomy2 from "../images/T8517R.jpg";

const useStyles = makeStyles(() => ({
  slider:{
    width:"500px",
    marginTop:"35px"
  },
  important:{
    fontWeight: "bold",
    color: theme.palette.secondary.main
  },
  t21: {
    color: "#9CC0E7"
  },
  t18 : {
    color: "#E1B78D"
  },
  t13: {
    color: "#b19cd9"
  },
  banner:{
    height:"60vh", 
    width:"100vw" ,
    display:"flex",
    position:"relative",
    backgroundPosition: 'center',
    backgroundSize: 'cover', 
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    left:"calc(-50vw + 50%)"
  },
  image1:{
    backgroundImage:`url(${trisomy1})`
  },
  image2:{
    backgroundImage:`url(${trisomy2})`
  }
}))

const DEFAULT_AGE = 30

function getDataSeparated(age) {
  return [
    {y:data.t21[age]},
    {y:data.t18[age]},
    {y:data.t13[age]},   
  ]
}

export default function Trisomies() {
  const {t} = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [percent, setPercent] = useState(false)
  const [age, setAge] = useState(DEFAULT_AGE)
  const [graphicDataTotal, setGraphicDataTotal] = useState(getDataTotal(DEFAULT_AGE));
  const [graphicDataSeparated, setGraphicDataSeparated] = useState(getDataSeparated(DEFAULT_AGE));
  const dataAverageTotal = [{y:16}, {y:9984}]
  const dataAverageSeparated = [{y:13}, {y:2}, {y:1}]

  function getDataTotal(age){
    const total = data.selectorTotal[age]
    return [{y: total}, {y: 10000 - total, x:""}]
  }

  const handleAgeChange = (event, newValue) => {
    setAge(newValue)
    setGraphicDataTotal(getDataTotal(newValue))
    setGraphicDataSeparated(getDataSeparated(newValue))
  };

  const handlePercentToggle = () => {
    setPercent(!percent);
  }

  const legendData = {
    noCase:{
      color:"#F7DBD7",
      label:t("No case of trisomy")
    },
    case:{
      color:"#4A4948",
      label:t("Total")
    },
    T21:{
      color:"#9CC0E7",
      label:`${t("Cases of trisomy")} 21`
    },
    T18:{
      color:"#E1B78D",
      label:`${t("Cases of trisomy")} 18`
    },
    T13:{
      color:"#b19cd9",
      label:`${t("Cases of trisomy")} 13`
    }
  }

  return (
    <Layout pageTitle={t("Trisomies")} next="/screening">
      <SectionTitle>
        {t("Trisomy")} 21
      </SectionTitle>
      <Typography >
        <strong>
        {t("Trisomy 21 (Down syndrome)")} 
        </strong>
      </Typography>
      <ul>
        <Li>
          <Trans i18nKey="T21">
            Is caused by the presence of an extra
            <Definition word="chromosome">chromosome</Definition> 
            in the 21st pair of chromosomes. A person with trisomy 21 therefore has 47 chromosomes instead of 46.
          </Trans>
        </Li>
        <Li>
          {t("Is the most common viable chromosomal abnormality.")}
        </Li>
        <li>
          <Typography className={classes.important}>
            {t("Affects about one in 770 births.")}
          </Typography>
        </li>
        <Li>
          {t("Can cause health problems (e.g. heart or bowel defects, vision or hearing problems, epilepsy).")}
        </Li>
        <br/>
        <Typography>
          <strong>
            {t("People with Down syndrome may:")}
          </strong>
        </Typography>
        <Li>
          {t("Need varying degrees of support throughout their lives.")} 
        </Li>
        <Li>
          {t("Hold a job and, in some cases, lead an almost independent life.")}
        </Li>
        <Li>
          {t("Develop meaningful emotional relationships and lead rewarding lives when given their rightful place.")}
        </Li>
        <br/>
        <Typography>
          <strong>
            {t("Life expectancy")}
          </strong>
        </Typography>
        <Li>
          {t("Trisomy21.1")}
        </Li>
        <Li>
          {t("Trisomy21.2")}
        </Li>
        <Li>
          {t("Trisomy21.3")}
        </Li>
      </ul>
      <SectionTitle>
        {t("Trisomy")} 18 
      </SectionTitle>
      <Typography>
        <strong>{t("Trisomy 18 (Edwards syndrome)")}</strong>
      </Typography>
      <ul>
        <Li>
          <Trans i18nKey="T18">
            Is caused by the presence of an extra chromosome in the 18th pair of 
            <Definition word="chromosome">chromosome</Definition>.
          </Trans>
        </Li>
        <li>
          <Typography className={classes.important}>
            {t("Affects about 1 in 4,500 births")}
          </Typography>
        </li>
        <Li>
          {t("Is often associated with potentially severe malformations or stunted growth during pregnancy or after birth.")}
        </Li>
        <br/>
        <Typography>
          <strong>
            {t("Life expectancy")}
          </strong>
        </Typography>
        <Li>
          {t("More than 95% of fetuses with trisomy 18 die during pregnancy.")} 
        </Li>
        <Li>
          {t("Most babies born with trisomy 18 will die within the first year of life. About 5% to 10% survive beyond one year.")}
        </Li>
      </ul>
      <SectionTitle>
        {t("Trisomy")} 13
      </SectionTitle>
      <Typography>
        <strong>{t("Trisomy 13 (Patau syndrome)")}</strong>
      </Typography>
      <ul>
        <Li>
          <Trans i18nKey="T13">
            Is caused by the presence of an extra  
            <Definition word="chromosome">chromosome</Definition> 
            in the 13th chromosome pair.
          </Trans>
        </Li>
        <li>
          <Typography className={classes.important}>
            {t("Affects about 1 in 7,000 births.")}
          </Typography>
        </li>
        <Li>
          {t("Is very severe and is often associated with multiple malformations or miscarriage.")}
        </Li>
        <br/>
        <Typography>
          <strong>
            {t("Life expectancy")}
          </strong>
        </Typography>
        <Li>
          {t("More than 95% of fetuses with trisomy 13 die during pregnancy.")}
        </Li>
        <Li>
          {t("Most babies born with trisomy 13 will die within the first year of life. Fewer than 10% live beyond one year.")}
        </Li>
      </ul>
      <div className={`${classes.image1} ${classes.banner}`}/>
      <Section title={t("In figures")}>
        <Typography>
          {t("graphExplanation1")}
        </Typography>
        <Box display="flex"
             flexDirection={isSmallScreen ? "row" : "column"}
             justifyContent="center"
             alignItems="center"
             width="100%">
          <PieChart data={dataAverageTotal}
                    animate={false}
                    title={t("Number estimation for all 3 trisomies")}
                    colorScale={["#4A4948", "#F7DBD7" ]}
                    centerLabel="10 000"/>
          <PieChart data={dataAverageSeparated}
                    animate={false}
                    title={t("Number estimation for each type of trisomies")}
                    colorScale={["#9CC0E7", "#E1B78D", "#b19cd9"]}
                    centerLabel={"16"}/>
        </Box>
        <Legend elements={legendData}/>
        <Box display="flex" 
             flexDirection="column" 
             alignItems="center">
          <Typography variant="body1" gutterBottom={true}>
            <em>
              {t("Out of 10,000 women...")}
              <br/>
              <br/>
              {t("On average")}, <strong>{dataAverageSeparated[0].y}</strong> {t("carry a foetus with")} <strong className={classes.t21}>{t("trisomy")} 21</strong>, <strong>{dataAverageSeparated[1].y}</strong>  {t("carry a foetus with")} <strong className={classes.t18}>{t("trisomy")} 18</strong> {t("and")} <strong>{dataAverageSeparated[2].y}</strong> {t("carry singular")} <strong className={classes.t13}>{t("trisomy")} 13</strong>. 
            </em>
          </Typography>
        </Box>
      </Section> 
      <div className={`${classes.image2} ${classes.banner}`}/>
      <SectionTitle>{t("Risk factors")}</SectionTitle>
      <Typography color="secondary">
          <em>
              {t("A number of factors can influence the development of trisomy.")}
              <br/>
              <br/>
          </em>
      </Typography>
      <Typography component="span">
        <strong>{t("Probability according to the mother's age")}</strong>
        <br/>
        <br/>
        <Trans i18nKey="graphExplanation">
          Click on your age in the horizontal bar to find out your personal <Definition word="probability">probability</Definition> of having a child with <Definition word="trisomy">trisomy</Definition>. 
          According to the age you have indicated, the graph on the left presents the probability that you will have a child with trisomy on a sample of 10,000 women. The graph on the right takes 
          the probability obtained in the graph on the left and divides it into three groups: trisomy 21, trisomy 18, and trisomy 13.
        </Trans>
      </Typography>
        <Box display="flex"
             flexDirection="column"
             maxWidth="100%"
             marginTop="5px"
             alignItems="center"
             justifyContent="center">
          <Box display="flex" maxWidth="100%">
            <Slider
              className={classes.slider}
              defaultValue={DEFAULT_AGE}
              aria-labelledby="discrete-slider-always"
              marks={data.marks}
              valueLabelDisplay="on"
              onChange={ handleAgeChange }
              min={15}
              max={50}
            />
          </Box>
          <Box marginLeft="auto">
            <FormControlLabel
              control={
                <Switch
                  checked={percent}
                  onChange={handlePercentToggle}
                  name="percent"
                  color="primary"
                />
              }
              label={t("Percentage")}
            />
          </Box>
        </Box>
        <Box display="flex"
             flexDirection={isSmallScreen ? "row" : "column"}
             justifyContent="center"
             alignItems="center"
             width="100%">
          <PieChart data={graphicDataTotal} 
                    colorScale={["#4A4948", "#F7DBD7" ]}
                    total={10000}
                    centerLabel={'10 000'}
                    title={t("Number estimation for all 3 trisomies")}
                    percent={percent}/>
          <PieChart data={graphicDataSeparated} 
                    colorScale={["#9CC0E7", "#E1B78D", "#b19cd9"]}
                    total={10000}
                    title={t("Number estimation for each type of trisomies")}
                    centerLabel={graphicDataTotal[0].y.toString()}
                    percent={percent}/>
      </Box>
      <Legend elements={legendData}/>
      <Typography gutterBottom={true}>
          <em>
            {t("Out of 10,000 women...")}
            <br/>
            <br/>
            {t("At")} <strong>{age}</strong> {t("years")}, <strong>{percent? `${graphicDataSeparated[0].y / 100}%` : graphicDataSeparated[0].y} </strong>{t("carry a foetus with")} <strong className={classes.t21}>{t("trisomy")} 21</strong>, <strong>{percent? `${graphicDataSeparated[1].y / 100}%` : graphicDataSeparated[1].y}</strong> {t("carry a foetus with")} <strong className={classes.t18}>{t("trisomy")} 18</strong> {t("and")} <strong>{percent? `${graphicDataSeparated[2].y / 100}%` : graphicDataSeparated[2].y}</strong> {t("carry a foetus with")} <strong className={classes.t13}>{t("trisomy")} 13</strong>. 
          </em>
        </Typography>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

